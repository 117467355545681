import { useRef, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { Dialog } from '@dx-ui/osc-dialog-v2';
import { useCycle } from '@dx-ui/osc-cycle';
import { Pagination } from '@dx-ui/osc-pagination';
import { useRect } from '@dx-ui/utilities-use-rect';
import { GetHeightForWidthAndAspectRatio } from '../responsive-image/responsive-image';
import type { AspectRatio } from '../responsive-image/responsive-image-types';
import { TextBlockBody } from '../text-block/text-block-body';
import { TextBlockHeader } from '../text-block/text-block-header';
import Markdown from 'markdown-to-jsx';
import type { GetHotelAlertsQuery } from '../../generated/types';
import { Image } from '../image/Image';

export type DialogWithContentBase = {
  ariaLabel: string;
  id?: string;
  title?: string;
  headline?: string;
  content?: JSX.Element | string;
  link?: NonNullable<
    NonNullable<GetHotelAlertsQuery['hotelPageTemplate']>['hotel']
  >['alerts'][number]['link'];
  isAlert?: boolean;
  onClose?: () => void;
  onDismiss?: () => void;
  onClickLink?: () => void;
  alertMessages?: NonNullable<
    NonNullable<GetHotelAlertsQuery['hotelPageTemplate']>['hotel']
  >['alerts'];
};

type DialogWithContent = DialogWithContentBase & {
  isOpen: boolean;
  imageUrl?: string;
  imageAltText?: string;
  containerAspectRatio?: AspectRatio;
  imageAspectRatio?: AspectRatio;
};

const DialogWithContent = function (props: DialogWithContent) {
  const { cycleId, active, total, onNextClick, onPreviousClick } = useCycle();
  const clickNext = () => {
    onNextClick();
  };

  const clickPrevious = () => {
    onPreviousClick();
  };
  const [t] = useTranslation('dx-dialog-with-content');
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const modalCloseButtonRef = useRef<HTMLButtonElement>(null);

  const {
    imageAspectRatio = '3:2',
    imageUrl,
    imageAltText,
    isOpen,
    isAlert = false,
    id = 'dialog',
    ariaLabel = t('ariaLabel'),
    headline,
    onClose,
    alertMessages,
    ...dialogProps
  } = props;
  let { link, content } = props;
  const containerAspectRatio = props.containerAspectRatio || imageAspectRatio;
  const {
    title,
    content: _unusedContent,
    ...dialogPropsWithAlert
  } = isAlert ? { ...dialogProps, title: alertMessages?.[active]?.headline || '' } : dialogProps;
  const height = GetHeightForWidthAndAspectRatio(rect?.width ?? 0, containerAspectRatio);

  const ariaLabelLink = [link?.adaDescription || '', link?.isNewWindow ? t('newWindow') : '']
    .filter(Boolean)
    .join(' - ');

  useEffect(() => {
    if (isOpen) {
      modalCloseButtonRef.current && modalCloseButtonRef.current.focus();
    }
  }, [isOpen, id]);

  const isHeadlineShown = headline && headline !== props?.title;

  let body: JSX.Element | null = null;
  if (isAlert) {
    content = <Markdown>{alertMessages?.[active]?.description}</Markdown>;
    link = alertMessages?.[active]?.link;
  }
  if (content) {
    if (typeof content === 'string') {
      body = <TextBlockBody {...props}>{content}</TextBlockBody>;
    } else {
      body = isAlert ? (
        // When we're displaying an alert, random line-breaks sometimes get added, so we need to pass the children in directly to avoid the issue
        <div className="dialog-with-content-content">
          <div
            className="my-2 leading-6"
            dangerouslySetInnerHTML={{ __html: content.props.children }}
          />
        </div>
      ) : (
        <div className="my-2 leading-6">{content}</div>
      );
    }
  }

  return (
    <Dialog
      data-testid="activeDialogWithContentBox"
      isOpen={isOpen}
      onDismiss={onClose}
      {...(title ? { title } : { ariaLabel })}
      size="3xl"
      {...dialogPropsWithAlert}
    >
      <div className="flex h-full flex-col sm:h-auto" ref={ref}>
        {imageUrl ? (
          <div
            className="flex items-center justify-items-center overflow-y-hidden"
            style={{ height }}
          >
            <Image
              id={id}
              aspectRatio={imageAspectRatio}
              src={imageUrl}
              alt={imageAltText || ''}
              width={rect?.width ?? 0}
            />
          </div>
        ) : null}

        <div className="box-border px-4 pb-2 pt-8 sm:px-8 md:px-16" aria-live="polite">
          {isHeadlineShown ? <TextBlockHeader {...props}>{headline}</TextBlockHeader> : null}

          {body}

          {link?.label && link?.url ? (
            <div className="inline-block w-full pb-2 pt-8 text-center">
              <a
                className="btn btn-primary btn-xl"
                href={link?.url}
                target={link?.isNewWindow ? '_blank' : '_self'}
                aria-label={ariaLabelLink}
                data-testid="dialogWithContentCTA"
                rel="noreferrer"
              >
                {link.label}
              </a>
            </div>
          ) : null}
          {(alertMessages?.length ?? 0) > 1 ? (
            <div className="mt-6 pr-6">
              <Pagination
                controls={cycleId}
                label="Alert"
                hideLabel
                current={active}
                total={total}
                onNextClick={clickNext}
                onPreviousClick={clickPrevious}
                loop
              />
            </div>
          ) : null}
        </div>
      </div>
    </Dialog>
  );
};

export default DialogWithContent;
