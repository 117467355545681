import { createContext, useContext } from 'react';

type CycleContext = {
  active: number;
  cycleId: string;
  total: number;
  hasSlides: boolean;
  onPreviousClick: () => void;
  onNextClick: () => void;
  setActive: (value: number) => void;
};
const CycleContext = createContext<CycleContext>({} as CycleContext);

export const useCycle = () => {
  const context = useContext(CycleContext);
  if (context === undefined) {
    throw new Error('useCycle must be used within a Cycle Component parent');
  }
  return context;
};

export { CycleContext };
export default CycleContext;
