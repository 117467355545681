import cx from 'classnames';
import * as React from 'react';
import { CycleContext } from './cycle.context';

export type CycleProps = {
  total: number;
  onChangeIndex?: (newIndex: number, method?: 'previous' | 'next') => void;
  current?: number;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * Adds controls for multiple slide items and `useCycle()` for all logic
 */
export const Cycle: React.FC<React.PropsWithChildren<CycleProps>> = ({
  current = 0,
  total,
  children,
  className,
  onChangeIndex,
  id,
  ...rest
}) => {
  const fallbackId = React.useId();
  const cycleId = id ?? `cycle-${fallbackId}`;
  const [active, setActive] = React.useState(current);
  const hasSlides = total > 1;
  const onPreviousClick = React.useCallback(() => {
    const index = active - 1 < 0 ? total - 1 : active - 1;
    setActive(index);
    onChangeIndex && onChangeIndex(index, 'previous');
  }, [active, total, onChangeIndex]);

  const onNextClick = React.useCallback(() => {
    const index = active + 1 >= total ? 0 : active + 1;
    setActive(index);
    onChangeIndex && onChangeIndex(index, 'next');
  }, [setActive, onChangeIndex, active, total]);

  const onSetActive = React.useCallback(
    (index: number) => {
      setActive(index);
      onChangeIndex && onChangeIndex(index);
    },
    [setActive, onChangeIndex]
  );

  return (
    <CycleContext.Provider
      value={{
        active,
        cycleId,
        hasSlides,
        total,
        onNextClick,
        onPreviousClick,
        setActive: onSetActive,
      }}
    >
      <section className={cx('relative w-full overflow-hidden', className)} {...rest}>
        {children}
      </section>
    </CycleContext.Provider>
  );
};

export default Cycle;
