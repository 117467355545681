import type * as React from 'react';
import { ControlNext, ControlBack } from '@dx-ui/osc-controls';
import { useTranslation } from 'next-i18next';

export type Pagination = {
  /**
   * `id` of the element pagination controls for usage with aria-controls
   */
  controls?: string;
  /**
   * Current active page (zero based)
   */
  current?: number;
  /**
   * The text displayed in front of the page numbers and used for a11y
   */
  label?: string;
  /**
   * Whether to visually hide the label
   */
  hideLabel?: boolean;
  /**
   * Controls whether or not to disable buttons at either end
   */
  loop?: boolean;
  /**
   * Total number of pages
   */
  total?: number;
  /**
   * When the page is selected from the dropdown
   */
  onChange?: (val: number) => void;
  onPreviousClick?: React.DOMAttributes<HTMLButtonElement>['onClick'];
  onNextClick?: React.DOMAttributes<HTMLButtonElement>['onClick'];
  /** Pagination item id */
  groupId?: string;
};

/**
 * Use when you need a control to cycle through "pages" or any paginated data.
 *
 * Pass the `current` page _(zero-based)_ and `total` pages, if you want to allow the select element to jump to a specific page, include the `onChange` callback. You can include listeners for `onPreviousClick` and `onNextClick` to change pages one by one.
 */
export const Pagination: React.FC<React.PropsWithChildren<Pagination>> = ({
  controls,
  loop,
  onChange,
  onPreviousClick,
  onNextClick,
  hideLabel,
  current = 0,
  total = 0,
  label,
  groupId,
}) => {
  const { t } = useTranslation('osc-pagination');
  // eslint-disable-next-line prefer-spread
  const pageNumbers = Array.apply(null, Array(total));
  const prevDisabled = loop ? false : current === 0;
  const nextDisabled = loop ? false : current === total - 1;

  const paginationLabel = label ?? t('defaultLabel');

  return (
    <div className="pointer-events-auto flex items-center justify-center space-x-4">
      <div className="sr-only" aria-live="polite">{`${paginationLabel} ${t('count', {
        current: current + 1,
        total,
      })}`}</div>
      <ControlBack
        id={groupId ? `pagination-left-${groupId}` : 'pagination-left'}
        size="lg"
        aria-controls={controls}
        onClick={onPreviousClick}
        disabled={prevDisabled}
        label={`${t('previousLabel', { label: paginationLabel })}, ${t('count', {
          current: current - 1 < 0 ? total : current,
          total,
        })}`}
      />
      <div className="flex items-center justify-center">
        {onChange ? (
          <>
            {!hideLabel && <span className="pr-2">{paginationLabel}</span>}
            <label>
              <span className="sr-only">{t('prompt', { label: paginationLabel })}</span>
              <select
                aria-controls={controls}
                className="form-select"
                value={current}
                onBlur={(e) => onChange(parseInt(e.currentTarget.value, 10))}
                onChange={(e) => onChange(parseInt(e.currentTarget.value, 10))}
              >
                {pageNumbers.map((_, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <option key={index} value={index}>
                    {index + 1}
                  </option>
                ))}
              </select>
            </label>
            <span className="pl-2">{t('count', { current: undefined, total })}</span>
          </>
        ) : (
          <span className="text-center">
            {!hideLabel && `${paginationLabel} `}
            <span className="tabular-nums">{t('count', { current: current + 1, total })}</span>
          </span>
        )}
      </div>
      <ControlNext
        id={groupId ? `pagination-right-${groupId}` : 'pagination-right'}
        size="lg"
        aria-controls={controls}
        onClick={onNextClick}
        disabled={nextDisabled}
        label={`${t('nextLabel', { label: paginationLabel })}, ${t('count', {
          current: current + 1 >= total ? 1 : current + 2,
          total,
        })}`}
      />
    </div>
  );
};

export default Pagination;
