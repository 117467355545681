import type * as React from 'react';
import cx from 'classnames';
import { CustomMarkdown } from '../markdown/CustomMarkdown';

type TextBlockBody = {
  children: React.ReactNode;
  className?: string;
  testId?: string | null;
};

const TextBlockBody: React.FC<TextBlockBody> = function ({
  children,
  className = '',
  testId = 'textBlock',
}) {
  return typeof children === 'string' ? (
    <div
      data-testid={testId || 'textBlock'}
      className={cx(className, 'py-2 sm:text-lg lg:text-xl')}
    >
      <CustomMarkdown>{children}</CustomMarkdown>
    </div>
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  );
};

export { TextBlockBody };
