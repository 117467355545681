import cx from 'classnames';

type TextBlockHeader = {
  children?: string;
  ariaLabel?: string;
  className?: string;
};

const TextBlockHeader = function ({ children, ariaLabel, className = '' }: TextBlockHeader) {
  return children ? (
    <h2
      aria-label={ariaLabel || children}
      className={cx(className, 'font-headline text-brand text-2xl lg:mb-2 lg:text-4xl')}
      data-testid="textBlockHeader"
    >
      {children}
    </h2>
  ) : null;
};

export { TextBlockHeader };
